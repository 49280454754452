<template>
  <b-modal
    ref="calModal"
    size="xl"
    id="cal_modal"
    hide-footer
    hide-header
    scrollable
  >
    <h3 class="font-size-h1">Loan Calculator</h3>

    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12">
            <b-form-group label="Loan Amount" label-for="txtAmount">
              <b-form-input
                class="form-control form-control"
                size="sm"
                id="txtAmount"
                name="txtAmount"
                v-model="form.amount"
                type="number"
                min="0"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group label="Term of Loan (months)" label-for="txtTerms">
              <b-form-input
                class="form-control form-control"
                size="sm"
                id="txtTerms"
                name="txtTerms"
                v-model="form.terms"
                type="number"
                min="1"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group label="Interest Rate (%)" label-for="txtInterestRate">
              <b-form-input
                class="form-control form-control"
                size="sm"
                id="txtInterestRate"
                name="txtInterestRate"
                v-model="form.interestRate"
                type="number"
                min="4"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group label="Payment Type" label-for="drpTenorType">
              <b-form-select
                v-model="form.tenor_type"
                :options="tenorTypes"
                id="drpTenorType"
                name="drpTenorType"
                @change="tenorTypeChange"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group label=" Date" label-for="txtDate" class="mb-0">
              <b-form-datepicker
                class="form-control form-control-solid"
                v-model="form.loanDate"
                size="sm"
                id="txtDate"
              ></b-form-datepicker>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 text-right">
        <div class="row">
          <div class="col-10">
            <b-form-group label="Total Payment" label-for="txtTotalPayment">
              <b-form-input
                id="txtTotalPayment"
                name="txtTotalPayment"
                v-model="form.totalPayment"
                size="sm"
                class="form-control form-control text-right"
                readonly
                disabled
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <b-form-group
              label="Total Interest Payment"
              label-for="txtInterestPayment"
            >
              <b-form-input
                id="txtInterestPayment"
                name="txtInterestPayment"
                v-model="form.totalInterest"
                size="sm"
                class="form-control form-control text-right"
                readonly
                disabled
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <b-form-group
              label="Equated Monthly Payment"
              label-for="txtMonthlyPayment"
              v-show="form.tenor_type == 'monthly'"
            >
              <b-form-input
                id="txtMonthlyPayment"
                name="txtMonthlyPayment"
                size="sm"
                class="form-control form-control text-right"
                v-model="form.monthlyPayment"
                readonly
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Equated Weekly Payment"
              label-for="txtMonthlyPayment"
              v-show="form.tenor_type == 'weekly'"
            >
              <b-form-input
                id="txtMonthlyPayment"
                name="txtMonthlyPayment"
                size="sm"
                class="form-control form-control text-right"
                v-model="form.monthlyPayment"
                readonly
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Equated Daily Payment"
              label-for="txtMonthlyPayment"
              v-show="form.tenor_type == 'daily'"
            >
              <b-form-input
                id="txtMonthlyPayment"
                name="txtMonthlyPayment"
                size="sm"
                class="form-control form-control text-right"
                v-model="form.monthlyPayment"
                readonly
                disabled
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex justify-content-start mb-2">
        <b-button
          size="sm"
          class="btn-danger text-uppercase mr-2"
          @click="reset"
          >reset</b-button
        >
        <b-button
          size="sm"
          class="btn-info text-uppercase mr-2"
          @click="calculate"
          >calculate</b-button
        >
      </div>
      <div class="row">
        <div class="col-12">
          <b-table class="text-right" :fields="fields" :items="items"></b-table>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/camelcase */
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  data() {
    return {
      tenorTypes: [
        { value: "monthly", text: "Monthly" },
        { value: "weekly", text: "Weekly" },
        { value: "daily", text: "Daily" }
      ],
      fields: [
        {
          key: "index",
          label: "Instalment"
        },
        {
          key: "paymentDate",
          label: "Payment Date"
        },
        {
          key: "monthlyPayment",
          label: "Monthly/ Weekly/ Daily Payment"
        },
        {
          key: "balance",
          label: "Balance"
        }
      ],
      items: [],
      form: {
        amount: 100000,
        interestRate: 1,
        terms: 1,
        loanDate: new Date(),
        monthlyPayment: 0,
        totalPayment: 0,
        totalInterest: 0,
        tenor_type: "monthly"
      }
    };
  },
  //   mounted() {
  //     this.$store.dispatch(SET_BREADCRUMB, [{ title: "Loan Calculator" }]);
  //   },
  methods: {
    reset() {
      this.form = {
        amount: 100000,
        interestRate: 1,
        terms: 1,
        loanDate: new Date(),
        monthlyPayment: 0,
        totalPayment: 0,
        totalInterest: 0,
        tenor_type: "monthly"
      };
    },
    calculate() {
      //FORMULA FOR CALUCLATING INTEREST RATE
      //p = x*(1 - (1+r)^-n)/r

      let n = this.form.terms;
      let a = this.form.amount;
      let r = this.form.interestRate / (100 * 12);
      let x = Math.pow(1 + r, n);
      let y = x - 1;
      let ratio = x / y;
      let emi = a * r * ratio;
      this.form.monthlyPayment = emi.toFixed(2);
      this.form.totalPayment = (emi * n).toFixed(2);
      this.form.totalInterest = (emi * n - a).toFixed(2);

      this.items = [];

      switch (this.form.tenor_type) {
        case "daily":
          n = n * 30;
          this.form.monthlyPayment = this.form.monthlyPayment / 30;
          break;
        case "weekly":
          n = n * 4;
          this.form.monthlyPayment = this.form.monthlyPayment / 4;
          break;
        default:
          n = n * 1;
          this.form.monthlyPayment = this.form.monthlyPayment / 1;
          break;
      }

      for (let index = 0; index < n; index++) {
        let item = {
          index: index + 1,
          paymentDate: new Date(
            this.form.loanDate.setMonth(this.form.loanDate.getMonth() + 1)
          )
            .toISOString()
            .slice(0, 10),
          monthlyPayment: this.form.monthlyPayment,
          balance: (
            this.form.totalPayment -
            this.form.monthlyPayment * (index + 1)
          ).toFixed(2)
        };
        this.items.push(item);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.p-font,
.form-group,
table {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
</style>
